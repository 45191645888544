import React from 'react'

import {
    Create,
    required,
    SimpleForm,
    useNotify,
    useRedirect,
    useTranslate,
} from 'react-admin'
import { Typography, useMediaQuery, useTheme } from '@mui/material';
import styledFormInputs from '../../../ui/inputs/styledFormInputs';
import { isSuperAdmin } from '../../../helper/resourceHelper';
import NavigateBackBtn from '../../../ui/button/NavigateBackBtn';



const validateRequired = [required('Champs obligatoire')]




const VoucherCreate = ({ classes, ...props }) => {
    const translate = useTranslate();
    const theme = useTheme();
    const notify = useNotify();
    const redirect = useRedirect();

    const inputsDataGeneral = [
        { name: 'voucher_count', label: translate('resources.vouchers.voucher_count'), validate: validateRequired, type: "number" },
        { name: 'plan_id', label: translate('resources.vouchers.plan_id'), validate: validateRequired, type: "reference", reference: "device_sub_plans", optionText: "title" },
        { name: 'root_organization_id', label: translate('resources.vouchers.root_organization_id'), validate: validateRequired, type: "reference", reference: "root_organizations", optionText: "name" },
    ]

    const inputsDataPricing = [
        { name: 'price_without_tax', label: translate('resources.vouchers.price_without_tax'), validate: validateRequired, type: "number" },
        { name: 'currency', label: translate('resources.vouchers.currency'), validate: validateRequired, type: "select", choices: [{ id: 'eur', name: 'EUR' }] },
    ]




    const onSuccess = () => {
        notify(translate('resources.vouchers.create_success'));
        redirect('/vouchers');
    }

    return (
        <>
            <Typography sx={{ color: theme.palette.text.primary, textAlign: 'start', fontWeight: 700, fontSize: "24px", lineHeight: "42px", margin: "0px 0px 16px 0px" }}>
                <NavigateBackBtn /> {translate('resources.vouchers.create')}
            </Typography>
            <Create
                redirect="list"
                title={'resources.vouchers.create'}
                sx={{
                    "& .MuiPaper-root": {
                        backgroundColor: theme.palette.background.paper,
                        color: theme.palette.text.primary,
                    },
                }}
                mutationOptions={{ onSuccess }}
            >

                <SimpleForm >

                    <Typography color="text.main" sx={{
                        fontFamily: "Roboto",
                        fontSize: "16px",
                        fontWeight: 700,
                        lineHeight: "25px",
                        textAlign: "center",
                        margin: "0px 0px 10px 0px"
                    }}
                    >
                        {translate('resources.vouchers.sections.general')}
                    </Typography>
                    {styledFormInputs(inputsDataGeneral)}
                    <Typography color="text.main" sx={{
                        fontFamily: "Roboto",
                        fontSize: "16px",
                        fontWeight: 700,
                        lineHeight: "25px",
                        textAlign: "center",
                        margin: "20px 0px 10px 0px"
                    }}
                    >
                        {translate('resources.vouchers.sections.pricing')}
                    </Typography>
                    {styledFormInputs(inputsDataPricing)}





                </SimpleForm>
            </Create>
        </>
    )
}

export default VoucherCreate



